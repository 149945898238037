type Urls = {
  URL_REST: string;
  URL_IO: string;
  IO_PATH?: string;
};

export const URLS: Urls = {
  // Dev
  // URL_REST: 'http://192.168.1.38:3001/v1',
  // URL_IO: 'ws://192.168.1.38:3001',
  // Dev Sandbox
  // URL_REST: 'https://api.ideploycode.com.br/easy/v1',
  // URL_IO: 'wss://api.ideploycode.com.br',
  // IO_PATH: '/easy/socket.io',
  // Prod server 1 
  URL_REST: 'https://api.softwareeasy.com.br/v1',
  URL_IO: 'wss://api.softwareeasy.com.br',
  // Prod server 2
  // URL_REST: 'https://api2.softwareeasy.com.br/v1',
  // URL_IO: 'wss://api2.softwareeasy.com.br',
}